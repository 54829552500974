<template>
  <QuestionGroup />
</template>

<script>
import { QuestionGroup } from 'exam-service-web-lib';

export default {
  name: 'QuestionGroupPage',
  components: {
    QuestionGroup,
  },
};
</script>
